<template>
  <div class="app-container">
    <el-row class="grid-content">
      <el-col :span="18">
        <div class="videoboxmain">
          <video class="videobox" :poster="pagedata.CoverImage" :src="pagedata.ResourceUrl" controls="controls" controlslist="nodownload"></video>
        </div>
        <h3>{{pagedata.CourseName}}</h3>
      </el-col>
      <el-col :span="6">
        <div class="listbox">
          <div class="list" @click="changePagedata(item)" v-for="(item,index) in list" :key="index">{{item.CourseName}}
            <div><i class="el-icon-video-play"></i> {{item.CourseDuration}}</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="body">
      <div class="titlebox">
        <div class="title">课程介绍</div>
      </div>
      <div
        class="content"
      >{{pagedata.CourseDesc}}</div>
    </div>
  </div>
</template>

<script>
import API from '@/api/config'
// import { ResourceInfo, ResourceList } from "@/api/online";
export default {
  name: "study-details",
  data() {
    return {
      Id: null,
      CatelogId: null,
      SubjectId: null,
      GradeId: null,
      pagedata:{},
      list: []
    };
  },
  created() {
      this.inte();
    //屏蔽鼠标右键
    document.oncontextmenu = function () {
      return false;
    }
  },
  methods: {
    inte(){
      this.Id = this.$route.query.Id;
       this.CatelogId = this.$route.query.CatelogId;
       this.SubjectId = this.$route.query.SubjectId;
       this.GradeId = this.$route.query.GradeId;
      // this.Id = "d120e88b-bece-4b0b-9778-2e6b99d3dfea";
      // this.CatelogId= "32744916-00ac-4c0b-9b94-d6ee92faca99";
      this.getpagedata();
      this.listdata();
    },
    getpagedata() {
      API.Course.ResourceInfo({
        Id: this.Id
      }).then(res => {
        this.pagedata = res.data
      });
    },
    listdata() {
      API.Course.ResourceList({
        CatelogId: this.CatelogId,
        GradeId: this.GradeId,
        SubjectId: this.SubjectId,
        PageIndex: 1,
        PageSize: 999
      }).then(res => {
        this.list = res.data.Data;
      });
    },
    changePagedata(item){
      this.Id =item.ID
      this.getpagedata();
    }
  },
  watch: {
   /* $route: function(to, from) {
      //执行数据更新查询
      // this.inte();
    }*/
  }
};
</script>

<style scoped lang="less">
.grid-content {
  margin-top: 50px;
  height: 600px;
  background: #eee;
  padding: 30px 0;

  .videoboxmain {
    height: 500px;
    .videobox {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .listbox {
    overflow-y: scroll;
    height: 500px;
    background: #000;
    color: #fff;
    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      // display: none;
      /**/
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #666;
    }

    .list {
      padding: 14px 10px;
      border-bottom: 1px dashed #fff;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.5;
      cursor: pointer;
      &:hover {
        color: rgba(var(--themecolor),1);
        background: #ccc;
      }
    }
  }
}
.body {
  margin: 40px 0;
  .titlebox {
    border-bottom: 1px solid #ccc;
    padding: 12px 0;
    .title {
      border-left: 4px solid rgba(var(--themecolor),0.75);
      padding-left: 20px;
      font-size: 20px;
      line-height: 20px;
    }
  }
  .content {
    padding: 20px;
    white-space: pre-wrap;
    color: #999;
    line-height: 1.5;
  }
}
</style>
